<template>
  <div class="calendar-select-range">
    <div class="date-select-item" @click="dateSelect('start')">
      {{ start }}
      <van-icon name="arrow-down" />
    </div>
    <div style="padding: 0 0.5rem">~</div>
    <div class="date-select-item" @click="dateSelect('end')">
      {{ end }}
      <van-icon name="arrow-down" />
    </div>

    <van-calendar
      ref="vanCalendar"
      v-model="calendarShow"
      :min-date="minTime || minDate"
      :max-date="maxTime || maxDate"
      @confirm="confirmDate($event, 'day')"
      :default-date="defaultDate"
      :show-confirm="false"
    >
      <template #title>
        <span class="title">
          {{ dateSelectType == 'start' ? '起始日期' : '截止日期' }}</span
        >
      </template>
    </van-calendar>
  </div>
</template>

<script>
//
import { formatTime } from '@/utils/date';

export default {
  name: 'calendarSelectRange',
  props: {
    start: {
      // sync
      type: String,
    },
    end: {
      // sync
      type: String,
    },
    maxTime: {
      type: [String, Date],
    },
    minTime: {
      type: [String, Date],
    },
    type: {
      default: 'calendar',
      // default: 'picker',
      type: String,
    },
  },
  data() {
    return {
      dateSelectType: undefined, // 当前日期选择 start end
      calendarShow: false,
    };
  },
  computed: {
    minDate() {
      // 这部分注释不要删
      // if (
      //   this.dateSelectType == "end" &&
      //   this.start
      // )
      //   return new Date(this.start.replace(/\-/g, "/"));
      // else return new Date(2020, 1, 1);
      return new Date(new Date().getFullYear() - 3, 1, 1);
    },
    maxDate() {
      // 这部分注释不要删
      // if (
      //   this.dateSelectType == "start" &&
      //   this.end
      // )
      //   return new Date(this.end.replace(/\-/g, "/"));
      // else return undefined;
      return new Date(new Date().getFullYear() + 3, 1, 1);
    },
    defaultDate() {
      let date;
      if (this.dateSelectType == 'start') {
        date = this.start;
      } else {
        date = this.end;
      }

      if (date) {
        return new Date(date.replace(/-/g, '/'));
      } else {
        return new Date();
      }
    },
  },
  methods: {
    dateSelect(type) {
      this.dateSelectType = type;

      if (this.type == 'calendar') {
        this.calendarShow = true;
      }
    },
    confirmDate(e, type) {
      switch (type) {
        case 'day':
          {
            let start = this.start,
              end = this.end;
            let date = formatTime(new Date(e), '-').slice(0, 10);

            if (this.dateSelectType == 'start') {
              start = date;
              this.$emit('update:start', date);
              this.dateSelectType = 'end';
            } else {
              end = date;
              this.$emit('update:end', date);
              this.calendarShow = false;
            }

            if (start && end && new Date(start) > new Date(end)) {
              this.$emit('update:start', end);
              this.$emit('update:end', start);
            }

            this.$emit('change');
          }

          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.calendar-select-range {
  display: flex;
  align-items: center;

  .date-select-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8.75rem;
    height: 2.5rem;
    border: 1px solid #eeeeee;
    border-radius: 0.5rem;
    box-sizing: border-box;
    font-size: 0.875rem;
    .van-icon-arrow-down {
      font-size: 0.875rem;
    }
  }

  .title {
    color: @titleColor;
    font-size: 1rem;
    font-weight: bold;
  }
}
</style>
